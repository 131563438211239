#header {
  height: var(--header-height);
  padding-top: 2em;
  padding-bottom: 1em;
  background-color: #f76902;
}

#header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

#subHeader {
  font-weight: bold;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  #nav-buttons {
    display: none;
  }
}

@media only screen and (max-device-width: 768px) {
  #nav-buttons {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  #hamburger-menu {
    display: none;
  }
}

@media only screen and (min-device-width: 769px) {
  #hamburger-menu {
    display: none;
  }
}
